import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import ContentCarousel from 'components/common/contentCarousel'
import iot from '/static/images/use-case/by-industry/iot_device_manufacturer_hero.webp'
import iotCard from '/static/images/use-case/by-industry/iot_device_manufacturer_bar.webp'
import { iotFeatures } from '/static/data/use-case/iot-device.static'

const iotDeviceManufacturer = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Use Case: IoT Device Manufacturer - Mlytics`,
            metaDescription: `With Mlytics' World-class delivery architecture to preserve your valuable origin resource, it's easier for smartphone makers and IoT device manufacturers to perform routine large files updates or deliver critical digital assets securely.`,
            metaUrl: 'www.mlytics.com/use-case/iot-device-manufacturer',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="IoT Device Manufacturer"
            title="World-class architecture to preserve your valuable origin resource"
            imgSrc={iot}
            imgAlt="IOT Device Hero Picture"
            paragraphContent="Easing the stress of origin is your business need, but it’s our mission. Leverage our platform and worry less about your origin."
            actionLinkOne="chatbot"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard
              title="For IoT Device Makers"
              imgSrc={iotCard}
              imgAlt="For IoT Device Makers"
              actionLink="/contact-us"
              actionText="Contact us"
              actionColor="btn-cover-orange"
            >
              <div className="py-[60px] px-10 w-[60%] bg-experience-gradient flex flex-col gap-5">
                <p className="h5-subtext-medium text-white">
                  After-sales updates are not just updates. It’s operations, sales, marketing, even branding. They are
                  the most critical tasks to keep your devices safe, make your customers happy, and build a stronger
                  brand in the long run. Mlytics platform handles 10MB to 500MB of single file with millions of download
                  requests for our customers on daily basis.
                </p>
                <ul className="flex flex-col h5-subtext-medium text-white ml-7">
                  <li className="list-disc">Firmware Updates</li>
                  <li className="list-disc">OS Updates</li>
                  <li className="list-disc">Security Patches</li>
                </ul>
                <p className="h5-subtext-medium text-white">
                  Are you happy with the performance of these tasks? Let’s talk.
                </p>
                <Link
                  to="/contact-us"
                  className="btn-cover-orange h4-content-text-bold text-white bg-secondary-600 px-[18px] py-3 rounded w-fit"
                >
                  Contact us
                </Link>
              </div>
            </WideCard>
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <ContentCarousel listItem={iotFeatures} />
          </div>
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default iotDeviceManufacturer

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
