import React from 'react'
import { Link } from 'gatsby'

import carouselOne from '/static/images/use-case/by-industry/iot_device_manufacturer_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-industry/iot_device_manufacturer_feature_2.webp'
import carouselThree from '/static/images/use-case/by-industry/iot_device_manufacturer_feature_3.webp'
import carouselFour from '/static/images/use-case/by-industry/iot_device_manufacturer_feature_4.webp'
import carouselFive from '/static/images/use-case/by-industry/iot_device_manufacturer_feature_5.webp'
import { clickMessageBirdChat } from 'utils'

export const iotFeatures = [
  {
    title: 'Future-proof design',
    content:
      'For DevOps, the real game begins AFTER a device is shipped. There’s no changing the hardware once a device is shipped. Outdated sold devices, outdated file directory on sold devices, changes on origin database or URL directory, all of this would cause significant challenges and cost to maintain and manage critical tasks such as firmware downloads, OS releases or security updates, which directly impact customer satisfactions and future sales. <br /><br /> Mlytics Platform not only merges major networks like Akamai and AWS, but also continuously crafts cutting-edge technologies such as advancing caching, pre-fetching and routing among all networks to solve this issue.',
    imageSrc: carouselOne,
    imageAlt: 'Future-proof design',
    children: (
      <div>
        <Link
          to="/use-case/hyper-speed-files-delivery"
          class="block h5-subtext-medium text-blue-100 underline py-3 w-fit"
        >
          Learn more.
        </Link>
        <button className="bg-secondary-600 rounded">
          <Link to="/contact-us" className="block w-full h-full py-2 px-3 text-white">
            See demo
          </Link>
        </button>
      </div>
    )
  },
  {
    title: 'Multi-network routing elasticity',
    content:
      'Internet is alive and the connectivity changes every mill-second. Plus, every network has its own priority on resources, bandwidth and capacity. Havning multi-networks doesn’t only mean having multiple choices, it means you get to create a smooth delivery path for every customer every single time. ',
    imageSrc: carouselTwo,
    imageAlt: 'Multi-network routing elasticity',
    children: (
      <Link to="/platform/decisive-engine" className="block h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </Link>
    )
  },
  {
    title: 'Most advanced caching and pre-fetching',
    content:
      'Caching in one network is easy but it’s not enough. Through years of experiences and processing hundreds of PBs in all networks, Mlytics has developed specific technologies and know how to your caching and pre-fetching strategies among all major networks in the most sophisticated way to fit your needs.',
    imageSrc: carouselThree,
    imageAlt: 'Most advanced caching and pre-fetching',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'Optimize costs at egress and midgrass levels',
    content: `When it comes to a very large scale of delivery, hidden costs in egress and midgrass become critical. Mlytics platform allows you to implement different approach such as using WAF to filter request traffic to avoid unnecessary midgrass traffic while maintaining high cach hit-rate and availability.`,
    imageSrc: carouselFour,
    imageAlt: 'Optimize costs at egress and midgrass levels',
    hintContent: `Up to 30% cost saving on egress and midgrass traffic.`,
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'Accurate monitoring of successful download rates',
    content: `Leveraging delivery network’s capacity is inevitable for large-scale download requests, but this also means decrease of visibility on successful download rates from end user perspective because there are two many edge servers to track. Mlytics provides a holistic, accurate and near real-time reporting from all edge servers across our platform.`,
    imageSrc: carouselFive,
    imageAlt: 'Accurate monitoring of successful download rates',
    hintContent: `1-min data interval granularity / 30-min data analytics immediacy.`
  }
]
